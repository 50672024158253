<template>
  <div class="informationupload">
    <div class="informationupload_con">
      <div class="title">
        {{detailData.applicantName}}
      </div>
      <div class="item1">
        <div>
          <p class="personal">营业执照复印件</p>
          <el-upload
            class="upload-demo"
            drag
            :headers="headers"
            action="/admin/sys-file/upload"
            multiple
            :auto-upload="true"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="(e) => uploadFn(e,10)"
            >
            <div v-if="!src1">
              <i style="color: #ACB6DD" class="el-icon-upload"></i>
              <div class="el-upload__text">JPG/PNG/PDF,  <2MB</div>
            </div>
            <div v-else>
              <img v-if="src1.indexOf('pdf') == -1" :src="src1" alt="">
              <img v-else src="../../assets/images/informationupload/PDF.png" alt="">
            </div>
          </el-upload>
        </div>
        <div  class="example_con">
          <p class="example">示例</p>
          <img @click="show(1)" src="../../assets/images/informationupload/personalbusiness.jpg" alt="">
        </div>

      </div>

      <div v-if="detailData.applicantType != 'enterprise'" class="item1">
        <div>
          <p class="personal">身份证</p>
          <el-upload
            class="upload-demo"
            drag
            :headers="headers"
            action="/admin/sys-file/upload"
            :auto-upload="true"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="(e) => uploadFn(e,11)"
            >
            <div v-if="!src2">
              <i style="color: #ACB6DD" class="el-icon-upload"></i>
              <div class="el-upload__text">JPG/PNG/PDF,  <2MB</div>
            </div>
            <div v-else>
              <img v-if="src2.indexOf('pdf') == -1" :src="src2" alt="">
              <img v-else src="../../assets/images/informationupload/PDF.png" alt="">
            </div>
          </el-upload>
        </div>
        <div  class="example_con">
          <p class="example">示例</p>
          <img @click="show(2)" src="../../assets/images/informationupload/sfz.jpg" alt="">
        </div>

      </div>

      <div class="item1">
        <div>
          <p class="personal">委托书</p>
          <el-upload
            class="upload-demo"
            drag
            :headers="headers"
            action="/admin/sys-file/upload"
            :auto-upload="true"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="(e) => uploadFn(e,12)"
            >
            <div v-if="!src3">
              <i style="color: #ACB6DD" class="el-icon-upload"></i>
              <div class="el-upload__text">JPG/PNG/PDF,  <2MB</div>
              <div class="el-upload__">（1）请参照示例签署委托书； </div>
              <div class="el-upload__">（2）请上传彩色扫描件(签署完成)</div>
            </div>
            <div v-else>
              <img v-if="src3.indexOf('pdf') == -1" :src="src3" alt="">
              <img v-else src="../../assets/images/informationupload/PDF.png" alt="">
            </div>

          </el-upload>
        </div>
        <div  class="example_con">
          <p class="example">
            示例
            <el-button @click="downLoad" style="font-size: 14px;color: #304CD1;margin-left: 58px" type="text">
              下载模版
              <i class="el-icon-download"></i>
            </el-button>
          </p>
          <img @click="show(3)" src="../../assets/images/informationupload/weituoshu.jpg" alt="">
        </div>

      </div>
      <div class="table-box">
        <el-button @click="submit" type="primary">确认提交</el-button>
      </div>
    </div>

    <el-dialog class="informationupload" :center="true" width="500" title="示例" :visible.sync="dialogTableVisible">
      <div style="display: flex;justify-content: center">
        <div style="width: 400px;height: auto">
          <img style="width: 100%;height: auto" :src="imgSrc" alt="">
        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: "informationupload",
    data () {
      return {
        imgSrc: '',
        dialogTableVisible: false,
        index: 0,
        name: '',
        detailData: {

        },
        ruleForm: {

        },
        //上传图片header
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        src1: '',
        src2: '',
        src3: '',
      }
    },
    mounted () {
      this.ruleForm = JSON.parse(localStorage.getItem('ruleForm'))
      this.getInfo()
    },
    watch: {
      index(newVal){
        if(this.detailData.applicantType == "enterprise"){
          if (newVal == 2){
              this.$router.push('/hostingPlatforms/trademark')
            this.$message('提交成功');
          }
        }else {
          if (newVal == 3){
            this.$router.push('/hostingPlatforms/trademark')
            this.$message('提交成功');
          }
        }
      }
    },
    components: {  },
    methods: {
      getInfo(){
     this.$axios.post(`/custody/renewal/order/detail`,{
          renewalOrderNumber: this.ruleForm.orderNumber
        }).then(({data}) => {

          if(data.code == 0){
            this.name = data.data.renewalOrderList.map(item => item.trademarkName).join(',')
            this.detailData = data.data.businessCustomer
          }
        })
      },
      show(val){
        if(val == 1){
          this.imgSrc = require("../../assets/images/informationupload/personalbusiness.jpg")
        }else if(val == 2){
          this.imgSrc = require("../../assets/images/informationupload/sfz.jpg")
        }else {
          this.imgSrc = require("../../assets/images/informationupload/weituoshu.jpg")
        }
        this.dialogTableVisible = true
      },
      submit(){
        if(this.detailData.applicantType == "enterprise"){
          this.upload(10)
          this.upload(12)
        }else {
          this.upload(10)
          this.upload(11)
          this.upload(12)
        }
      },
      upload(val){
        this.$axios
          .post("/custody/business/file/record/create",{
            orderCategory: 'trademark',
            orderNumber: this.detailData.orderNumber,
            fileType: val,
            fileUrl: val == 10 ? this.src1 : val == 11 ? this.src2 :val == 12 ? this.src3 : ''
          }).then(({data}) => {
          if(data.code == 0) {
              this.index++
          }
        }).catch(err => {
          this.$message('提交失败');
        })
      },
      downLoad(){
        this.$axios
          .post("/custody/renewal/order/export/trademarkAgencyAuthorizationLetter",{
            //申请人名称
            cnApplicantName: this.detailData.applicantName,
            //申请人地址
            tmNameCn: this.name,
            //联系人名称
            contact: this.detailData.contactPersonName,
            //联系电话
            phone: this.detailData.contactPersonPhone,
            //邮政编码
            zipCode: this.detailData.contactPostCode,
          }).then(({data}) => {
          if(data.code == 0) {
            window.open(data.data, '_blank')
          }
        })
      },
      uploadChangeFn(file, fileList) {
        let imgSize = Number(file.size / 1024 / 1024);

        if (imgSize > 2) {
          this.$message('文件大小不能超过2MB，请重新上传');
          this.materialList[scope.$index].fileList.splice(scope.index, 1);
          return;
        }
      },
      //新增监控图片上传前的钩子
      beforeAvatarUpload(file) {
        const isLt5M = file.size < 1024 * 1024 * 2
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 2MB!')
          return false
        }
        return true
      },      //新增监控上传图片成功
      uploadFn(response,e) {
        if (response.code == 0){
          switch (e) {
            case 10: this.src1 = response.data.url;break;
            case 11: this.src2 = response.data.url;break;
            case 12: this.src3 = response.data.url;break;
          }
        }
        else this.$message('图片上传失败')
      },

    }
  }
</script>
<style lang="less" scoped>
  .informationupload {
    height: 781px;
    overflow-y: auto;
    .informationupload_con {
      padding: 23px 46px;
      /*width: 100%;*/
      border-radius: 8px;
      background: #ffffff;

      .table-box {
        margin-top: 100px;
        display: flex;
        justify-content: left;
      }
      .title {
        width: 420px;
        padding: 10px 0 10px 40px;
        box-sizing: border-box;
        background: #D3DBF8;
        border-radius: 0px 8px 8px 0px;
        position: relative;
        left: -46px;
        font-weight: 500;
        font-size: 18px;
        color: #1E3292;
        letter-spacing: 1px;
      }
      .sub_title {
        font-size: 16px;
        color: #424242;
        margin-top: 4px;
      }
      .item1 {
        margin-top: 25px;
        display: flex;
        /deep/.el-upload-dragger {
          background: #F9F9FE;
          border-radius: 8px;
          height: 198px;
          border: 2px solid #ADB6DB;
          img {
            height: 190px;
            width: auto;
          }
          .el-upload__text {
            font-weight: 400;
            font-size: 14px;
            color: #888888;
            margin-bottom: 10px;
          }
          .el-upload__ {
            font-weight: 400;
            font-size: 12px;
            color: #414A77;
          }
        }
        .example_con {
          cursor: pointer;
          margin-left: 115px;
          .example {
            font-weight: 500;
            font-size: 16px;
            color: #424242;
            /deep/.el-button {
              padding: 0;
            }
          }
          img {
            border-radius: 20px;
            border: 5px solid #37476D;
            margin-top: 17px;
            width: 133px;
            height: auto;
          }
        }

        .personal {
          font-weight: 600;
          font-size: 16px;
          color: #424242;
          position: relative;
          padding-left: 9px;
          margin-bottom: 18px;
          &::before {
            position: absolute;
            content: '';
            width: 4px;
            height: 17px;
            background: #465CFC;
            top: 4px;
            left: 0;
          }
        }
      }
    }
  }
</style>
